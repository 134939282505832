<template>
  <div class="fixed top-0 left-0 right-0 z-50">
    <nav
      class="bg-white border-gray-200 px-2 sm:px-4 py-2.5"
    >
      <div class="flex flex-wrap justify-between items-center px-4">
        <button
          class="flex items-center"
          type="button"
          aria-label="Zur Startseite"
          @click="scrollTo('/startseite')"
        >
          <img
            :src="menu.image.src"
            class="mr-3 w-9 h-9"
            :alt="menu.image.alt"
          />
        </button>
        <div class="flex lg:order-2">
          <button
            @click="scrollTo('/termin-vereinbaren')"
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2.5 sm:px-5 py-2.5 text-center mr-5"
          >
            <span class="text-xs sm:text-base">{{ menu.appointment }}</span>
          </button>
          <HamburgerUi
            ref="hamburger"
            v-on:activeChange="toggleState"
          ></HamburgerUi>
        </div>
        <div
          class="justify-between items-center w-full lg:flex lg:w-auto lg:order-1 transition-all lg:opacity-100 lg:translate-x-0"
          v-bind:class="{
            '-translate-x-6': !isMenuActive,
            'opacity-0': !isMenuActive,
          }"
        >
          <ul
            v-bind:class="{ hidden: !isMenuActive }"
            class="lg:flex flex-col mt-4 lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium"
          >
            <TopmenuItemUi
              @click="$refs.hamburger.toggleState() & scrollTo('/startseite')"
              :title="menu.home"
              :isActive="scrollService.isMenuItemActive('LandingArea')"
            ></TopmenuItemUi>
            <TopmenuItemUi
              @click="$refs.hamburger.toggleState() & scrollTo('/dienstleistungen')"
              :title="menu.services"
              :isActive="scrollService.isMenuItemActive('ServiceArea')"
            ></TopmenuItemUi>
            <TopmenuItemUi
              @click="$refs.hamburger.toggleState() & scrollTo('/ueber-uns')"
              :title="menu.aboutUs"
              :isActive="scrollService.isMenuItemActive('AboutArea')"
            ></TopmenuItemUi>
            <TopmenuItemUi
              @click="$refs.hamburger.toggleState() & scrollTo('/ihre-vorteile')"
              :title="menu.qualityPromise"
              :isActive="scrollService.isMenuItemActive('QualityPromisesArea')"
            ></TopmenuItemUi>
            <TopmenuItemUi
              @click="$refs.hamburger.toggleState() & scrollTo('/patienten-meinungen')"
              :title="menu.socialProof"
              :isActive="scrollService.isMenuItemActive('SocialProofArea')"
            ></TopmenuItemUi>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { ScrollService } from "../../services/ScrollService";
import TopmenuItemUi from "./TopmenuItemUi.vue";
import HamburgerUi from "./HamburgerUi.vue";

ScrollService.getInstance().init();

export default {
  name: "TopmenuUi",
  components: {
    TopmenuItemUi,
    HamburgerUi,
  },
  data: () => {
    return {
      isMenuActive: false,
      scrollService: ScrollService.getInstance(),
    };
  },
  methods: {
    toggleState: function (active) {
      this.isMenuActive = active;
    },
    scrollTo: function (route) {
      if (this.$router.history.current.path === '/impressum' || this.$router.history.current.path === '/datenschutz') {
        this.$router.push('/startseite').then(() => {
          setTimeout(() => {this.scrollService.scrollTo(route);}, 600)
        })
      } else {
        this.scrollService.scrollTo(route);
      }
    }
  },
  computed: {
    area() {
      return ScrollService.getInstance().scrolling.area;
    },
  },
  props: {
    menu: Object,
  },
};
</script>

<style></style>
