<template>
  <div
    ref="container"
    id="serviceArea"
    class="lg:min-h-screen w-full snap-start relative lg:scroll-py-60 lg:pb-60 md:px-24 pb-2 pt-24 overflow-x-hidden"
    v-touch:swipe.left="leftSwipe"
    v-touch:swipe.right="rightSwipe"
  >
    <div class="flex w-full lg:min-h-screen lg:py-16">
      <div
        class="flex flex-col-reverse lg:flex-row justify-between lg:justify-center lg:mr-24 xl:mr-32 max-w-full"
      >
        <div
          class="lg:block mx-auto relative w-fit h-fit lg:my-auto lg:p-16 xl:p-24 lg:m-6"
        >
          <div class="hidden lg:block">
            <div
              class="absolute left-0 bottom-30 top mix-blend-multiply rounded-full w-96 h-96 bg-blue-400 blur-xl opacity-30 animate-blob"
            ></div>
            <div
              class="absolute left-0 bottom-20 mix-blend-multiply rounded-full w-96 h-96 bg-red-400 blur-xl opacity-20 animate-blob animation-delay-2000"
            ></div>
          </div>

          <ul
          :class="isAreaActive() ? '' : 'hidden'"
            class="fixed justify-center left-0 right-0 bottom-0 z-40 bg-white lg:relative transition duration-300 ease-in-out lg:animate-none flex lg:block lg:bg-white p-6 lg:w-96 max-w-md rounded-lg lg:border lg:border-gray-200 lg:shadow-md text-gray-900"
          >
            <li
              v-for="service in services"
              :key="service.id"
              class="w-3 h-3 lg:py-3 mx-3 lg:mx-none lg:border-b lg:p-1 lg:border-gray-200 lg:h-fit lg:w-full rounded-full lg:rounded"
              :class="
                selected && selected.id === service.id
                  ? 'bg-blue-600 text-white'
                  : 'bg-blue-300 lg:bg-white'
              "
            >
              <button
                type="button"
                v-if="service"
                v-on:click="contentChange(service)"
                class="w-3 h-3 lg:w-auto lg:h-auto"
              >
                <span class="lg:block hidden">{{ service.title }}</span>
              </button>
            </li>
          </ul>
        </div>
        

        <div>
          <div
            class="flex lg:h-full flex-row flex-nowrap justify-center align-center px-12 lg:py-12"
          >
            <div
              v-for="service in services"
              class="duration-700 h-fit self-center"
              :key="service.id"
              data-carousel-item
              :class="
                selected && selected.id === service.id ? 'active' : 'hidden'
              "
            >
              <h1
                class="mb-4 text-3xl md:text-5xl font-bold tracking-tight text-gray-900"
              >
                {{ service.title }}
              </h1>
              <p class="font-normal text-gray-700 text-gray-700 mb-8 leading-7 blocked">
                {{ service.text }}
              </p>
            </div>
          </div>

          <button
            type="button"
            aria-label="eine Dienstleistung weiter"
            @click="rightSwipe()"
            class="flex lg:hidden absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
          >
            <span class="inline-flex justify-center items-center">
              <svg
                class="w-5 h-5 text-blue-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
              <span class="hidden">eine Dienstleistung weiter</span>
            </span>
          </button>
          <button
            type="button"
            aria-label="eine Dienstleistung zurück"
            class="flex lg:hidden absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
            @click="leftSwipe()"
          >
            <span class="inline-flex justify-center items-center">
              <svg
                class="w-5 h-5 text-blue-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
              <span class="hidden">eine Dienstleistung zurück</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ScrollService } from "../../../services/ScrollService";

export default {
  name: "ServiceArea",
  props: {
    services: Array,
    seo: Object,
  },
  data() {
    return {
      selected:
        this.services && this.services.length > 0 ? this.services[0] : null,
      leftSwipeAction: Boolean
    };
  },
  mounted() {
    setTimeout(
      () =>
       ScrollService.getInstance().addArea(
          "ServiceArea",
          this.$refs.container,
          this.seo.browserTabTitle,
          this.seo.url,
          this.seo.metaDescription,
          this.seo.metaKeywords
        ),
      100
    );
  },
  methods: {
    contentChange: function (service) {
      this.selected = service;
    },
    leftSwipe: function () {
      this.leftSwipeAction = true;
      if (this.services) {
        const newIndex = this.services.indexOf(this.selected) + 1;
        if (this.services.length > newIndex) {
          const newService = this.services[newIndex];
          this.contentChange(newService);
        }
      }
    },
    rightSwipe: function () {
      this.leftSwipeAction = false;
      if (this.services) {
        const newIndex = this.services.indexOf(this.selected) - 1;
        if (0 <= newIndex) {
          const newService = this.services[newIndex];
          this.contentChange(newService);
        }
      }
    },
    isAreaActive() {
        return ScrollService.getInstance().isMenuItemActive('ServiceArea')
    }
  },
};
</script>

<style>
.hideAnimation {
  -webkit-animation: hideAnimation 5s forwards;
  animation: hideAnimation 5s forwards;
}
@keyframes hideAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes hideAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.blocked {
	text-align: justify;
	text-align-last: none;
  hyphens: auto;
}
</style>
