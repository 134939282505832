<template>
    <a class="rounded-full bg-gray-100 flex p-3 w-fit mr-4 mt-4 cursor-pointer" :href="socialMedia.link">
        <img class="w-5 h-5 lg:mr-3" v-if="socialMedia.icon" :src="mediaProvider + socialMedia.icon.url" :alt="socialMedia.icon.alternativeText"/>
        <span class="hidden lg:block text-gray-600 text-sm font-bold">{{socialMedia.name}}</span>
    </a>    
</template>

<script>
import * as env from "../../environments";

export default {
    name: 'SocialMedia',
    props: {
        socialMedia: Object
    },
    data: () => {
        return {
            mediaProvider: env.environments.mediaProvider
        }
    }
}
</script>

<style>

</style>