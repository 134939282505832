<template>
  <div class="flex flex-col text-center items-center justify-start w-72 h-fit m-3">
    <div class="bg-blue-900 rounded-3xl w-fit h-fit m-auto p-3">
      <img class="w-24 h-24" :src="mediaProvider + icon.url" :alt="icon.alternativeText" />
    </div>
    <h3 class="text-lg mt-7 font-bold">{{title}}</h3>
    <p class="text-gray-600 mt-3">{{description}}</p>
  </div>
</template>

<script>
import * as env from "../../environments";

export default {
  name: "QualityPromiseUi",
  props: {
    title: String,
    description: String,
    icon: Object
  },
    data: () => {
        return {
            mediaProvider: env.environments.mediaProvider
        }
    }
};
</script>

<style></style>
