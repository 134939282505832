import Router from 'vue-router'
import HomePage from './../components/pages/HomePage.vue'
import PrivacyArea from '../components/areas/PrivacyArea.vue'
import ImpressumArea from '../components/areas/ImpressumArea.vue'

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', redirect: '/startseite' },
        { path: '/startseite', component: HomePage },
        { path: '/dienstleistungen', component: HomePage },
        { path: '/ueber-uns', component: HomePage },
        { path: '/ihre-vorteile', component: HomePage },
        { path: '/patienten-meinungen', component: HomePage },
        { path: '/termin-vereinbaren', component: HomePage },
        { path: '/datenschutz', component: PrivacyArea },
        { path: '/impressum', component: ImpressumArea },
    ]
})