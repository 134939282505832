<template>
  <div
    ref="container"
    id="landingArea"
    class="grid md:grid-cols-2 h-screen snap-start scroll-pb-60"
  >
    <div class="m-auto sm:w-full px-7 sm:px-12 xl:w-3/4 md:order-1 order-2">
      <div class="pt-6 relative">
        <h5
          class="mb-4 text-3xl md:text-5xl font-bold tracking-tight text-gray-900"
        >
          {{ title }}
        </h5>
        <p class="font-normal text-gray-700 text-gray-400 mb-5">
          {{ subtitle }}
        </p>
        <div class="flex flex-col">
          <button
            v-scroll-to="'#appointmentArea'"
            class="relative bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded hover:scale-110 ease-in-out duration-150 w-72 max-w-full overflow-hidden h-10"
          >
            <TransitionGroup name="list"
              enter-active-class="transition-all duration-500 opacity-0"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition-all duration-500 opacity-100"
              leave-to-class="opacity-0 translate-y-24"
              appear>
              <span class="absolute inset-0 p-2" key="0" v-if="appointmentButton === 0">{{ appointment }}</span>
              <span class="absolute inset-0 p-2" key="1" v-if="appointmentButton === 1">Online {{ appointment }}</span>
              <span class="absolute inset-0 p-2" key="2" v-if="appointmentButton === 2">Telefonisch {{ appointment }}</span>
            </TransitionGroup>
          </button>
          <p class="font-bold text-red-700 w-fit">
            {{ redSubtitle }}
          </p>
        </div>
        
      </div>
      <div class="flex mt-3 sm:mt-7 md:mt-10 max-w-full">
        <SocialMedia
          v-for="socialMedia in socialMedias"
          :key="socialMedia.id"
          :socialMedia="socialMedia"
        ></SocialMedia>
      </div>
    </div>
    <div
      class="md:order-2 order-1 flex justify-center overflow-hidden h-full min-h-[30vh]"
    >
      <div class="w-full xl:w-fit xl:mr-10 md:animate-movey">
        <img
          class="object-cover h-full w-full md:h-1/2 md:w-96 md:my-5 rounded md:mx-auto"
          :src="mediaProvider + imagesLeft[0].url"
          :srcset="mediaProvider + imagesLeft[0].formats.large.url + ' 830w,'+   
                  mediaProvider + imagesLeft[0].formats.medium.url + ' 622w,'+  
                  mediaProvider + imagesLeft[0].formats.small.url + ' 415w,'+  
                  mediaProvider + imagesLeft[0].formats.thumbnail.url + ' 129w'"
          :alt="imagesLeft[0].alternativeText"
          loading="lazy"
        />
        <img
          class="hidden w-full md:block object-cover h-1/2 w-96 my-5 rounded mx-auto"
          :src="mediaProvider + imagesLeft[1].url"
          :srcset="mediaProvider + imagesLeft[1].formats.large.url + ' 830w,'+   
                  mediaProvider + imagesLeft[1].formats.medium.url + ' 622w,'+  
                  mediaProvider + imagesLeft[1].formats.small.url + ' 415w,'+  
                  mediaProvider + imagesLeft[1].formats.thumbnail.url + ' 129w'"
          :alt="imagesLeft[1].alternativeText"
          loading="lazy"
        />
        <img
          class="hidden w-full md:block object-cover h-1/2 w-96 my-5 rounded mx-auto"
          :src="mediaProvider + imagesLeft[2].url"
          :srcset="mediaProvider + imagesLeft[2].formats.large.url + ' 830w,'+   
                  mediaProvider + imagesLeft[2].formats.medium.url + ' 622w,'+  
                  mediaProvider + imagesLeft[2].formats.small.url + ' 415w,'+  
                  mediaProvider + imagesLeft[2].formats.thumbnail.url + ' 129w'"
          :alt="imagesLeft[2].alternativeText"
          loading="lazy"
        />
      </div>
      <div class="w-0 hidden xl:w-fit xl:block md:animate-moveyd">
        <img
          v-for="imageRight in imagesRight"
          :key="imageRight.id"
          class="w-full object-cover h-1/2 w-96 max-w-full my-5 rounded mx-auto"
          :src="mediaProvider + imageRight.url"
          :srcset="mediaProvider + imageRight.formats.large.url + ' 830w,'+   
                  mediaProvider + imageRight.formats.medium.url + ' 622w,'+  
                  mediaProvider + imageRight.formats.small.url + ' 415w,'+  
                  mediaProvider + imageRight.formats.thumbnail.url + ' 129w'"
          :alt="imageRight.alternativeText"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SocialMedia from "../../ui/SocialMedia.vue";
import { ScrollService } from "../../../services/ScrollService";
import * as env from "../../../environments";

export default {
  name: "LandingArea",
  components: {
    SocialMedia,
  },
  props: {
    title: String,
    subtitle: String,
    redSubtitle: String,
    appointment: String,
    imagesLeft: Array,
    imagesRight: Array,
    socialMedias: Array,
    seo: Object,
  },
  mounted() {
    setTimeout(() => ScrollService.getInstance().addArea(
      "LandingArea",
      this.$refs.container,
      this.seo.browserTabTitle,
      this.seo.url,
      this.seo.metaDescription,
      this.seo.metaKeywords,
      true
    ), 100)
    this.handleAppointmentButton();
  },
  data: () => {
    return {
      mediaProvider: env.environments.mediaProvider,
      appointmentButton: 0,
    };
  },
  methods: {
    handleAppointmentButton: function () {
        setTimeout(() => {
          if(this.appointmentButton === 2) {
            this.appointmentButton = 0;
          } else {
            this.appointmentButton += 1;
            this.handleAppointmentButton();
          }
        }, 4000);
     
    },
  },
};
</script>

<style>
</style>
