<template>
  <footer class="bg-blue-900 px-8 py-3 top-1 snap-end">
    <div class="flex justify-center items-center pb-12 lg:pb-6">
      <router-link
        class="block text-white mr-3"
        type="button"
        to="/impressum"
      >
        Impressum
      </router-link>
      <router-link
        class="block text-white"
        type="button"
        to="/datenschutz"
      >
        Datenschutz
      </router-link>
    </div>
    <div></div>
  </footer>
</template>

<script>
export default {
  name: "FooterUi",
  props: {
    footer: Object,
  }
};
</script>

<style></style>
