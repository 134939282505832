import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import VueMeta from 'vue-meta'
import router from './router'
import Router from 'vue-router'
import Vue2TouchEvents from 'vue2-touch-events'
import resize from 'vue-resize-directive'

Vue.use(Vue2TouchEvents)
Vue.use(VueMeta)
Vue.use(Router)

Vue.directive('resize', resize)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
