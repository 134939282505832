<template>
  <div class="mt-24 px-3">
    <h1 class="mb-4 text-3xl md:text-5xl font-bold tracking-tight text-gray-900">Datenschutzerkl&auml;rung</h1>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900" id="m14">Einleitung</h2>
    <p>
      Mit der folgenden Datenschutzerkl&auml;rung m&ouml;chten wir Sie
      dar&uuml;ber aufkl&auml;ren, welche Arten Ihrer personenbezogenen Daten
      (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und
      in welchem Umfang verarbeiten. Die Datenschutzerkl&auml;rung gilt f&uuml;r
      alle von uns durchgef&uuml;hrten Verarbeitungen personenbezogener Daten,
      sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere
      auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
      Onlinepr&auml;senzen, wie z.B. unserer Social-Media-Profile (nachfolgend
      zusammenfassend bezeichnet als "Onlineangebot“).
    </p>
    <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
    <p>Stand: 4. Juli 2022</p>
    <p class="seal">
      <a
        href="https://datenschutz-generator.de/"
        title="Rechtstext von Dr. Schwenke - f&uuml;r weitere Informationen bitte anklicken."
        target="_blank"
        rel="noopener noreferrer nofollow"
        ><img
          src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png"
          alt="Rechtstext von Dr. Schwenke - f&uuml;r weitere Informationen bitte anklicken."
          width="250"
          height="250"
      /></a>
    </p>
    <h2  class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5">Inhalts&uuml;bersicht</h2>
    <ul class="index">
      <li><a class="index-link" href="#m14">Einleitung</a></li>
      <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
      <li>
        <a class="index-link" href="#mOverview"
          >&Uuml;bersicht der Verarbeitungen</a
        >
      </li>
      <li>
        <a class="index-link" href="#m13">Ma&szlig;gebliche Rechtsgrundlagen</a>
      </li>
      <li><a class="index-link" href="#m27">Sicherheitsma&szlig;nahmen</a></li>
      <li><a class="index-link" href="#m134">Einsatz von Cookies</a></li>
      <li>
        <a class="index-link" href="#m3575"
          >Im Rahmen der Gesch&auml;ftst&auml;tigkeit eingesetzte Anbieter und
          Services</a
        >
      </li>
      <li>
        <a class="index-link" href="#m225"
          >Bereitstellung des Onlineangebotes und Webhosting</a
        >
      </li>
      <li>
        <a class="index-link" href="#m104">Blogs und Publikationsmedien</a>
      </li>
      <li>
        <a class="index-link" href="#m182">Kontakt- und Anfragenverwaltung</a>
      </li>
      <li>
        <a class="index-link" href="#m391">Kommunikation via Messenger</a>
      </li>
      <li><a class="index-link" href="#m210">Bewerbungsverfahren</a></li>
      <li><a class="index-link" href="#m408">Umfragen und Befragungen</a></li>
      <li>
        <a class="index-link" href="#m263"
          >Webanalyse, Monitoring und Optimierung</a
        >
      </li>
      <li><a class="index-link" href="#m264">Onlinemarketing</a></li>
      <li>
        <a class="index-link" href="#m299"
          >Kundenrezensionen und Bewertungsverfahren</a
        >
      </li>
      <li>
        <a class="index-link" href="#m136"
          >Pr&auml;senzen in sozialen Netzwerken (Social Media)</a
        >
      </li>
      <li>
        <a class="index-link" href="#m328"
          >Plugins und eingebettete Funktionen sowie Inhalte</a
        >
      </li>
      <li>
        <a class="index-link" href="#m15"
          >&Auml;nderung und Aktualisierung der Datenschutzerkl&auml;rung</a
        >
      </li>
      <li><a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m3">Verantwortlicher</h2>
    <p>
      Arne Schuba, Stephan Feth, Physiotherapie Recovery Guide Schuba und Feth
      Partnerschaft
    </p>
    E-Mail-Adresse:
    <p>info@recovery-guide-physio.de</p>
    Telefon:
    <p>04131/ 2231722</p>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="mOverview">&Uuml;bersicht der Verarbeitungen</h2>
    <p>
      Die nachfolgende &Uuml;bersicht fasst die Arten der verarbeiteten Daten
      und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
      betroffenen Personen.
    </p>
    <h3>Arten der verarbeiteten Daten</h3>
    <ul>
      <li>Bestandsdaten.</li>
      <li>Zahlungsdaten.</li>
      <li>Kontaktdaten.</li>
      <li>Inhaltsdaten.</li>
      <li>Vertragsdaten.</li>
      <li>Nutzungsdaten.</li>
      <li>Meta-/Kommunikationsdaten.</li>
      <li>Bewerberdaten.</li>
    </ul>
    <h3>Besondere Kategorien von Daten</h3>
    <ul>
      <li>Gesundheitsdaten.</li>
    </ul>
    <h3>Kategorien betroffener Personen</h3>
    <ul>
      <li>Kunden.</li>
      <li>Interessenten.</li>
      <li>Kommunikationspartner.</li>
      <li>Nutzer.</li>
      <li>Bewerber.</li>
      <li>Gesch&auml;fts- und Vertragspartner.</li>
      <li>Patienten.</li>
      <li>Teilnehmer.</li>
    </ul>
    <h3>Zwecke der Verarbeitung</h3>
    <ul>
      <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
      <li>Kontaktanfragen und Kommunikation.</li>
      <li>Direktmarketing.</li>
      <li>Reichweitenmessung.</li>
      <li>Tracking.</li>
      <li>B&uuml;ro- und Organisationsverfahren.</li>
      <li>Verwaltung und Beantwortung von Anfragen.</li>
      <li>Bewerbungsverfahren.</li>
      <li>Feedback.</li>
      <li>Marketing.</li>
      <li>Profile mit nutzerbezogenen Informationen.</li>
      <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
      <li>Informationstechnische Infrastruktur.</li>
    </ul>
    <h3 id="m13">Ma&szlig;gebliche Rechtsgrundlagen</h3>
    <p>
      Im Folgenden erhalten Sie eine &Uuml;bersicht der Rechtsgrundlagen der
      DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
      nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
      Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
      k&ouml;nnen. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
      ma&szlig;geblich sein, teilen wir Ihnen diese in der
      Datenschutzerkl&auml;rung mit.
    </p>
    <ul>
      <li>
        <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die
        betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
        betreffenden personenbezogenen Daten f&uuml;r einen spezifischen Zweck
        oder mehrere bestimmte Zwecke gegeben.
      </li>
      <li>
        <strong
          >Vertragserf&uuml;llung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
          1 lit. b. DSGVO)</strong
        >
        - Die Verarbeitung ist f&uuml;r die Erf&uuml;llung eines Vertrags,
        dessen Vertragspartei die betroffene Person ist, oder zur
        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, die auf
        Anfrage der betroffenen Person erfolgen.
      </li>
      <li>
        <strong
          >Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong
        >
        - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
        Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
        Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
        die den Schutz personenbezogener Daten erfordern, &uuml;berwiegen.
      </li>
    </ul>
    <p>
      Zus&auml;tzlich zu den Datenschutzregelungen der
      Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in
      Deutschland. Hierzu geh&ouml;rt insbesondere das Gesetz zum Schutz vor
      Missbrauch personenbezogener Daten bei der Datenverarbeitung
      (Bundesdatenschutzgesetz – BDSG). Das BDSG enth&auml;lt insbesondere
      Spezialregelungen zum Recht auf Auskunft, zum Recht auf L&ouml;schung, zum
      Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
      personenbezogener Daten, zur Verarbeitung f&uuml;r andere Zwecke und zur
      &Uuml;bermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
      einschlie&szlig;lich Profiling. Des Weiteren regelt es die
      Datenverarbeitung f&uuml;r Zwecke des
      Besch&auml;ftigungsverh&auml;ltnisses (§ 26 BDSG), insbesondere im
      Hinblick auf die Begr&uuml;ndung, Durchf&uuml;hrung oder Beendigung von
      Besch&auml;ftigungsverh&auml;ltnissen sowie die Einwilligung von
      Besch&auml;ftigten. Ferner k&ouml;nnen Landesdatenschutzgesetze der
      einzelnen Bundesl&auml;nder zur Anwendung gelangen.
    </p>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m27">Sicherheitsma&szlig;nahmen</h2>
    <p>
      Wir treffen nach Ma&szlig;gabe der gesetzlichen Vorgaben unter
      Ber&uuml;cksichtigung des Stands der Technik, der Implementierungskosten
      und der Art, des Umfangs, der Umst&auml;nde und der Zwecke der
      Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und
      des Ausma&szlig;es der Bedrohung der Rechte und Freiheiten
      nat&uuml;rlicher Personen geeignete technische und organisatorische
      Ma&szlig;nahmen, um ein dem Risiko angemessenes Schutzniveau zu
      gew&auml;hrleisten.
    </p>
    <p>
      Zu den Ma&szlig;nahmen geh&ouml;ren insbesondere die Sicherung der
      Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten durch
      Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch
      des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung
      der Verf&uuml;gbarkeit und ihrer Trennung. Des Weiteren haben wir
      Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die
      L&ouml;schung von Daten und Reaktionen auf die Gef&auml;hrdung der Daten
      gew&auml;hrleisten. Ferner ber&uuml;cksichtigen wir den Schutz
      personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von
      Hardware, Software sowie Verfahren entsprechend dem Prinzip des
      Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
      Voreinstellungen.
    </p>
    <p>
      SSL-Verschl&uuml;sselung (https): Um Ihre via unserem Online-Angebot
      &uuml;bermittelten Daten zu sch&uuml;tzen, nutzen wir eine
      SSL-Verschl&uuml;sselung. Sie erkennen derart verschl&uuml;sselte
      Verbindungen an dem Pr&auml;fix https:// in der Adresszeile Ihres
      Browsers.
    </p>
    <h2  class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m134">Einsatz von Cookies</h2>
    <p>
      Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
      Informationen auf Endger&auml;ten speichern und Informationen aus den
      Endger&auml;ten auslesen. Z.B. um den Login-Status in einem Nutzerkonto,
      einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
      verwendete Funktionen eines Onlineangebotes speichern. Cookies k&ouml;nnen
      ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
      Funktionsf&auml;higkeit, Sicherheit und Komfort von Onlineangeboten sowie
      der Erstellung von Analysen der Besucherstr&ouml;me.
    </p>
    <p>
      <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang
      mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern
      eine vorhergehende Einwilligung ein, au&szlig;er wenn diese gesetzlich
      nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig,
      wenn das Speichern und das Auslesen der Informationen, also auch von
      Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen
      ausdr&uuml;cklich gew&uuml;nschten Telemediendienst (also unser
      Onlineangebot) zur Verf&uuml;gung zu stellen. Die widerrufliche
      Einwilligung wird gegen&uuml;ber den Nutzern deutlich kommuniziert und
      enth&auml;lt die Informationen zu der jeweiligen Cookie-Nutzung.
    </p>
    <p>
      <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf
      welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
      Daten der Nutzer mit Hilfe von Cookies verarbeiten, h&auml;ngt davon ab,
      ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen,
      ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erkl&auml;rte
      Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten
      Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem
      betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
      seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der
      Erf&uuml;llung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz
      von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
      erf&uuml;llen. Zu welchen Zwecken die Cookies von uns verarbeitet werden,
      dar&uuml;ber kl&auml;ren wir im Laufe dieser Datenschutzerkl&auml;rung
      oder im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.
    </p>
    <p>
      <strong>Speicherdauer:</strong>Im Hinblick auf die Speicherdauer werden
      die folgenden Arten von Cookies unterschieden:
    </p>
    <ul>
      <li>
        <strong
          >Tempor&auml;re Cookies (auch: Session- oder
          Sitzungs-Cookies):</strong
        >
        Tempor&auml;re Cookies werden sp&auml;testens gel&ouml;scht, nachdem ein
        Nutzer ein Online-Angebot verlassen und sein Endger&auml;t (z.B. Browser
        oder mobile Applikation) geschlossen hat.
      </li>
      <li>
        <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch
        nach dem Schlie&szlig;en des Endger&auml;tes gespeichert. So k&ouml;nnen
        beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte
        direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht.
        Ebenso k&ouml;nnen die mit Hilfe von Cookies erhobenen Daten der Nutzer
        zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine
        expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen (z.
        B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon
        ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei
        Jahre betragen kann.
      </li>
    </ul>
    <p>
      <strong
        >Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong
      >Nutzer k&ouml;nnen die von ihnen abgegebenen Einwilligungen jederzeit
      widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend
      den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen. Nutzer k&ouml;nnen
      ihren Widerspruch auch &uuml;ber die Einstellungen ihres Browsers
      erkl&auml;ren, z.B. durch Deaktivierung der Verwendung von Cookies (wobei
      dadurch auch die Funktionalit&auml;t unserer Online-Dienste
      eingeschr&auml;nkt sein kann). Ein Widerspruch gegen die Verwendung von
      Cookies zu Online-Marketing-Zwecken kann auch &uuml;ber die Websites
      <a href="https://optout.aboutads.info" target="_blank"
        >https://optout.aboutads.info</a
      >
      und
      <a href="https://www.youronlinechoices.com/" target="_blank"
        >https://www.youronlinechoices.com/</a
      >
      erkl&auml;rt werden.
    </p>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong
          >Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong
        >Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
        dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies,
        bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens
        genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
        verwaltet und widerrufen werden k&ouml;nnen. Hierbei wird die
        Einwilligungserkl&auml;rung gespeichert, um deren Abfrage nicht erneut
        wiederholen zu m&uuml;ssen und die Einwilligung entsprechend der
        gesetzlichen Verpflichtung nachweisen zu k&ouml;nnen. Die Speicherung
        kann serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie,
        bzw. mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung
        einem Nutzer, bzw. dessen Ger&auml;t zuordnen zu k&ouml;nnen.
        Vorbehaltlich individueller Angaben zu den Anbietern von
        Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der
        Speicherung der Einwilligung kann bis zu zwei Jahren betragen. Hierbei
        wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt
        der Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche
        Kategorien von Cookies und/oder Diensteanbieter) sowie dem Browser,
        System und verwendeten Endger&auml;t gespeichert.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m3575">
      Im Rahmen der Gesch&auml;ftst&auml;tigkeit eingesetzte Anbieter und
      Services
    </h2>
    <p>
      Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit nutzen wir unter Beachtung
      der gesetzlichen Vorgaben zus&auml;tzliche Dienste, Plattformen,
      Schnittstellen oder Plug-ins von Drittanbietern (kurz "Dienste"). Deren
      Nutzung beruht auf unseren Interessen an einer ordnungsgem&auml;&szlig;en,
      rechtm&auml;&szlig;igen und wirtschaftlichen F&uuml;hrung unseres
      Gesch&auml;ftsbetriebs und unserer internen Organisation.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
        Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
        Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern);
        Inhaltsdaten (z.B. Eingaben in Onlineformularen); Vertragsdaten (z.B.
        Vertragsgegenstand, Laufzeit, Kundenkategorie).
      </li>
      <li>
        <strong>Besondere Kategorien personenbezogener Daten:</strong>
        Gesundheitsdaten (Art. 9 Abs. 1 DGSVO).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Kunden; Interessenten; Nutzer
        (z.B. Webseitenbesucher, Nutzer von Onlinediensten); Gesch&auml;fts- und
        Vertragspartner; Patienten.
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
        Leistungen und Kundenservice; B&uuml;ro- und Organisationsverfahren.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Doctolib: </strong>Online Terminplanung und Terminverwaltung;
        <strong>Dienstanbieter:</strong> Doctolib GmbH, Wilhelmstra&szlig;e 118,
        Aufgang C, 10963 Berlin, Deutschland, Mutterunternehmen: Doctolib SAS,
        32 rue de Monceau 75008 Paris, Frankreich;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO); <strong>Website:</strong>
        <a href="https://www.doctolib.de" target="_blank"
          >https://www.doctolib.de</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a href="https://www.doctolib.de/terms/agreement" target="_blank"
          >https://www.doctolib.de/terms/agreement</a
        >.
      </li>
      <li>
        <strong>Instagram: </strong>Soziales Netzwerk;
        <strong>Dienstanbieter:</strong> Instagram Inc., 1601 Willow Road, Menlo
        Park, CA, 94025, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO);
        <strong>Website:</strong>
        <a href="https://www.instagram.com" target="_blank"
          >https://www.instagram.com</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a href="https://instagram.com/about/legal/privacy" target="_blank"
          >https://instagram.com/about/legal/privacy</a
        >.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
    <p>
      Um unser Onlineangebot sicher und effizient bereitstellen zu k&ouml;nnen,
      nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern in
      Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das
      Onlineangebot abgerufen werden kann. Zu diesen Zwecken k&ouml;nnen wir
      Infrastruktur- und Plattformdienstleistungen, Rechenkapazit&auml;t,
      Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und
      technische Wartungsleistungen in Anspruch nehmen.
    </p>
    <p>
      Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
      Daten k&ouml;nnen alle die Nutzer unseres Onlineangebotes betreffenden
      Angaben geh&ouml;ren, die im Rahmen der Nutzung und der Kommunikation
      anfallen. Hierzu geh&ouml;ren regelm&auml;&szlig;ig die IP-Adresse, die
      notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern zu
      k&ouml;nnen, und alle innerhalb unseres Onlineangebotes oder von Webseiten
      get&auml;tigten Eingaben.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in
        Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
        Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
        Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
        Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
        technischen Ger&auml;ten (Computer, Server etc.).).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>E-Mail-Versand und -Hosting: </strong>Die von uns in Anspruch
        genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den
        Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
        Adressen der Empf&auml;nger sowie Absender als auch weitere
        Informationen betreffend den E-Mailversand (z.B. die beteiligten
        Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die
        vorgenannten Daten k&ouml;nnen ferner zu Zwecken der Erkennung von SPAM
        verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im
        Internet grunds&auml;tzlich nicht verschl&uuml;sselt versendet werden.
        Im Regelfall werden E-Mails zwar auf dem Transportweg
        verschl&uuml;sselt, aber (sofern kein sogenanntes
        Ende-zu-Ende-Verschl&uuml;sselungsverfahren eingesetzt wird) nicht auf
        den Servern, von denen sie abgesendet und empfangen werden. Wir
        k&ouml;nnen daher f&uuml;r den &Uuml;bertragungsweg der E-Mails zwischen
        dem Absender und dem Empfang auf unserem Server keine Verantwortung
        &uuml;bernehmen; <strong>Rechtsgrundlagen:</strong> Berechtigte
        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
      <li>
        <strong>Hetzner: </strong>Leistungen auf dem Gebiet der Bereitstellung
        von informationstechnischer Infrastruktur und verbundenen
        Dienstleistungen (z.B. Speicherplatz und/oder Rechenkapazit&auml;ten);
        <strong>Dienstanbieter:</strong> Hetzner Online GmbH, Industriestr. 25,
        91710 Gunzenhausen, Deutschland;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO); <strong>Website:</strong>
        <a href="https://www.hetzner.com" target="_blank"
          >https://www.hetzner.com</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a
          href="https://www.hetzner.com/de/rechtliches/datenschutz"
          target="_blank"
          >https://www.hetzner.com/de/rechtliches/datenschutz</a
        >; <strong>Auftragsverarbeitungsvertrag:</strong>
        <a
          href="https://docs.hetzner.com/de/general/general-terms-and-conditions/data-privacy-faq/"
          target="_blank"
          >https://docs.hetzner.com/de/general/general-terms-and-conditions/data-privacy-faq/</a
        >.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m104">Blogs und Publikationsmedien</h2>
    <p>
      Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und
      Publikation (nachfolgend "Publikationsmedium"). Die Daten der Leser werden
      f&uuml;r die Zwecke des Publikationsmediums nur insoweit verarbeitet, als
      es f&uuml;r dessen Darstellung und die Kommunikation zwischen Autoren und
      Lesern oder aus Gr&uuml;nden der Sicherheit erforderlich ist. Im
      &Uuml;brigen verweisen wir auf die Informationen zur Verarbeitung der
      Besucher unseres Publikationsmediums im Rahmen dieser Datenschutzhinweise.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
        Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten
        (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte
        Webseiten, Interesse an Inhalten, Zugriffszeiten);
        Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
        Leistungen und Kundenservice; Feedback (z.B. Sammeln von Feedback via
        Online-Formular); Bereitstellung unseres Onlineangebotes und
        Nutzerfreundlichkeit.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Instagram: </strong>Soziales Netzwerk;
        <strong>Dienstanbieter:</strong> Instagram Inc., 1601 Willow Road, Menlo
        Park, CA, 94025, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO);
        <strong>Website:</strong>
        <a href="https://www.instagram.com" target="_blank"
          >https://www.instagram.com</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a href="https://instagram.com/about/legal/privacy" target="_blank"
          >https://instagram.com/about/legal/privacy</a
        >.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m182">Kontakt- und Anfragenverwaltung</h2>
    <p>
      Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon
      oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
      Gesch&auml;ftsbeziehungen werden die Angaben der anfragenden Personen
      verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger
      angefragter Ma&szlig;nahmen erforderlich ist.
    </p>
    <p>
      Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und
      Anfragedaten im Rahmen von vertraglichen oder vorvertraglichen Beziehungen
      erfolgt zur Erf&uuml;llung unserer vertraglichen Pflichten oder zur
      Beantwortung von (vor)vertraglichen Anfragen und im &Uuml;brigen auf
      Grundlage der berechtigten Interessen an der Beantwortung der Anfragen und
      Pflege von Nutzer- bzw. Gesch&auml;ftsbeziehungen.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
        Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
        Leistungen und Kundenservice; Kontaktanfragen und Kommunikation;
        Verwaltung und Beantwortung von Anfragen; Feedback (z.B. Sammeln von
        Feedback via Online-Formular); Bereitstellung unseres Onlineangebotes
        und Nutzerfreundlichkeit.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
        vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte
        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Kontaktformular: </strong>Wenn Nutzer &uuml;ber unser
        Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in
        Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang
        mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens. Zu diesem
        Zweck verarbeiten wir personenbezogene Daten im Rahmen vorvertraglicher
        und vertraglicher Gesch&auml;ftsbeziehungen, soweit dies zu deren
        Erf&uuml;llung erforderlich ist und im &Uuml;brigen auf Grundlage
        unserer berechtigten Interessen sowie der Interessen der
        Kommunikationspartner an der Beantwortung der Anliegen und unserer
        gesetzlichen Aufbewahrungspflichten;
        <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
        vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte
        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m391">Kommunikation via Messenger</h2>
    <p>
      Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten daher
      darum, die nachfolgenden Hinweise zur Funktionsf&auml;higkeit der
      Messenger, zur Verschl&uuml;sselung, zur Nutzung der Metadaten der
      Kommunikation und zu Ihren Widerspruchsm&ouml;glichkeiten zu beachten.
    </p>
    <p>
      Sie k&ouml;nnen uns auch auf alternativen Wegen, z.B. via Telefon oder
      E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten
      Kontaktm&ouml;glichkeiten oder die innerhalb unseres Onlineangebotes
      angegebenen Kontaktm&ouml;glichkeiten.
    </p>
    <p>
      Im Fall einer Ende-zu-Ende-Verschl&uuml;sselung von Inhalten (d.h., der
      Inhalt Ihrer Nachricht und Anh&auml;nge) weisen wir darauf hin, dass die
      Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angeh&auml;ngte
      Bilder) von Ende zu Ende verschl&uuml;sselt werden. Das bedeutet, dass der
      Inhalt der Nachrichten nicht einsehbar ist, nicht einmal durch die
      Messenger-Anbieter selbst. Sie sollten immer eine aktuelle Version der
      Messenger mit aktivierter Verschl&uuml;sselung nutzen, damit die
      Verschl&uuml;sselung der Nachrichteninhalte sichergestellt ist.
    </p>
    <p>
      Wir weisen unsere Kommunikationspartner jedoch zus&auml;tzlich darauf hin,
      dass die Anbieter der Messenger zwar nicht den Inhalt einsehen, aber in
      Erfahrung bringen k&ouml;nnen, dass und wann Kommunikationspartner mit uns
      kommunizieren sowie technische Informationen zum verwendeten Ger&auml;t
      der Kommunikationspartner und je nach Einstellungen ihres Ger&auml;tes
      auch Standortinformationen (sogenannte Metadaten) verarbeitet werden.
    </p>
    <p>
      <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir
      Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um
      eine Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung ihrer
      Daten deren Einwilligung. Im &Uuml;brigen, falls wir nicht um eine
      Einwilligung bitten und sie z.B. von sich aus Kontakt mit uns aufnehmen,
      nutzen wir Messenger im Verh&auml;ltnis zu unseren Vertragspartnern sowie
      im Rahmen der Vertragsanbahnung als eine vertragliche Ma&szlig;nahme und
      im Fall anderer Interessenten und Kommunikationspartner auf Grundlage
      unserer berechtigten Interessen an einer schnellen und effizienten
      Kommunikation und Erf&uuml;llung der Bed&uuml;rfnisse unser
      Kommunikationspartner an der Kommunikation via Messenger. Ferner weisen
      wir Sie darauf hin, dass wir die uns mitgeteilten Kontaktdaten ohne Ihre
      Einwilligung nicht erstmalig an die Messenger &uuml;bermitteln.
    </p>
    <p>
      <strong>Widerruf, Widerspruch und L&ouml;schung:</strong> Sie k&ouml;nnen
      jederzeit eine erteilte Einwilligung widerrufen und der Kommunikation mit
      uns via Messenger jederzeit widersprechen. Im Fall der Kommunikation via
      Messenger l&ouml;schen wir die Nachrichten entsprechend unseren generellen
      L&ouml;schrichtlinien (d.h. z.B., wie oben beschrieben, nach Ende
      vertraglicher Beziehungen, im Kontext von Archivierungsvorgaben etc.) und
      sonst, sobald wir davon ausgehen k&ouml;nnen, etwaige Ausk&uuml;nfte der
      Kommunikationspartner beantwortet zu haben, wenn kein R&uuml;ckbezug auf
      eine vorhergehende Konversation zu erwarten ist und der L&ouml;schung
      keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
    </p>
    <p>
      <strong>Vorbehalt des Verweises auf andere Kommunikationswege:</strong>
      Zum Abschluss m&ouml;chten wir darauf hinweisen, dass wir uns aus
      Gr&uuml;nden Ihrer Sicherheit vorbehalten, Anfragen &uuml;ber Messenger
      nicht zu beantworten. Das ist der Fall, wenn z.B. Vertragsinterna
      besonderer Geheimhaltung bed&uuml;rfen oder eine Antwort &uuml;ber
      Messenger den formellen Anspr&uuml;chen nicht gen&uuml;gt. In solchen
      F&auml;llen verweisen wir Sie auf ad&auml;quatere Kommunikationswege.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
        Telefonnummern); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
        Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
        Kommunikation; Direktmarketing (z.B. per E-Mail oder postalisch).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
        a. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Instagram: </strong>Nachrichtenversand &uuml;ber das sozialen
        Netzwerk Instagram; <strong>Dienstanbieter:</strong> Instagram Inc.,
        1601 Willow Road, Menlo Park, CA, 94025, USA;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO); <strong>Website:</strong>
        <a href="https://www.instagram.com" target="_blank"
          >https://www.instagram.com</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a href="https://instagram.com/about/legal/privacy" target="_blank"
          >https://instagram.com/about/legal/privacy</a
        >.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m210">Bewerbungsverfahren</h2>
    <p>
      Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die f&uuml;r deren
      Beurteilung und Auswahl erforderlichen Daten mitteilen. Welche
      Informationen erforderlich sind, ergibt sich aus der Stellenbeschreibung
      oder im Fall von Onlineformularen aus den dortigen Angaben.
    </p>
    <p>
      Grunds&auml;tzlich geh&ouml;ren zu den erforderlichen Angaben, die
      Informationen zur Person, wie der Name, die Adresse, eine
      Kontaktm&ouml;glichkeit sowie die Nachweise &uuml;ber die f&uuml;r eine
      Stelle notwendigen Qualifikationen. Auf Anfragen teilen wir
      zus&auml;tzlich gerne mit, welche Angaben ben&ouml;tigt werden.
    </p>
    <p>
      Sofern zur Verf&uuml;gung gestellt, k&ouml;nnen uns Bewerber ihre
      Bewerbungen mittels eines Onlineformulars &uuml;bermitteln. Die Daten
      werden entsprechend dem Stand der Technik verschl&uuml;sselt an uns
      &uuml;bertragen. Ebenfalls k&ouml;nnen Bewerber uns ihre Bewerbungen via
      E-Mail &uuml;bermitteln. Hierbei bitten wir jedoch zu beachten, dass
      E-Mails im Internet grunds&auml;tzlich nicht verschl&uuml;sselt versendet
      werden. Im Regelfall werden E-Mails zwar auf dem Transportweg
      verschl&uuml;sselt, aber nicht auf den Servern von denen sie abgesendet
      und empfangen werden. Wir k&ouml;nnen daher f&uuml;r den
      &Uuml;bertragungsweg der Bewerbung zwischen dem Absender und dem Empfang
      auf unserem Server keine Verantwortung &uuml;bernehmen.
    </p>
    <p>
      F&uuml;r Zwecke der Bewerbersuche, Einreichung von Bewerbungen und Auswahl
      von Bewerbern k&ouml;nnen wir unter Beachtung der gesetzlichen Vorgaben,
      Bewerbermanagement-, bzw. Recruitment-Software und Plattformen und
      Leistungen von Drittanbietern in Anspruch nehmen.
    </p>
    <p>
      Bewerber k&ouml;nnen uns gerne zur Art der Einreichung der Bewerbung
      kontaktieren oder uns die Bewerbung auf dem Postweg zuzusenden.
    </p>
    <p>
      <strong>Verarbeitung besonderer Kategorien von Daten:</strong> Soweit im
      Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen
      Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten, wie z.B.
      Schwerbehinderteneigenschaft oder ethnische Herkunft) bei Bewerbern
      angefragt werden, damit der Verantwortliche oder die betroffene Person die
      ihm bzw. ihr aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit
      und des Sozialschutzes erwachsenden Rechte aus&uuml;ben und seinen bzw.
      ihren diesbez&uuml;glichen Pflichten nachkommen kann, erfolgt deren
      Verarbeitung nach Art. 9 Abs. 2 lit. b. DSGVO, im Fall des Schutzes
      lebenswichtiger Interessen der Bewerber oder anderer Personen gem. Art. 9
      Abs. 2 lit. c. DSGVO oder f&uuml;r Zwecke der Gesundheitsvorsorge oder der
      Arbeitsmedizin, f&uuml;r die Beurteilung der Arbeitsf&auml;higkeit des
      Besch&auml;ftigten, f&uuml;r die medizinische Diagnostik, f&uuml;r die
      Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder
      f&uuml;r die Verwaltung von Systemen und Diensten im Gesundheits- oder
      Sozialbereich gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer auf
      freiwilliger Einwilligung beruhenden Mitteilung der besonderen Kategorien
      von Daten erfolgt deren Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit.
      a. DSGVO.
    </p>
    <p>
      <strong>L&ouml;schung von Daten:</strong> Die von den Bewerbern zur
      Verf&uuml;gung gestellten Daten k&ouml;nnen im Fall einer erfolgreichen
      Bewerbung f&uuml;r die Zwecke des Besch&auml;ftigungsverh&auml;ltnisses
      von uns weiterverarbeitet werden. Andernfalls, sofern die Bewerbung auf
      ein Stellenangebot nicht erfolgreich ist, werden die Daten der Bewerber
      gel&ouml;scht. Die Daten der Bewerber werden ebenfalls gel&ouml;scht, wenn
      eine Bewerbung zur&uuml;ckgezogen wird, wozu die Bewerber jederzeit
      berechtigt sind. Die L&ouml;schung erfolgt, vorbehaltlich eines
      berechtigten Widerrufs der Bewerber, sp&auml;testens nach dem Ablauf eines
      Zeitraums von sechs Monaten, damit wir etwaige Anschlussfragen zu der
      Bewerbung beantworten und unseren Nachweispflichten aus den Vorschriften
      zur Gleichbehandlung von Bewerbern nachkommen k&ouml;nnen. Rechnungen
      &uuml;ber etwaige Reisekostenerstattung werden entsprechend den
      steuerrechtlichen Vorgaben archiviert.
    </p>
    <p>
      <strong>Aufnahme in einen Bewerberpool:</strong> Die Aufnahme in einen
      Bewerber-Pool, sofern angeboten, erfolgt auf Grundlage einer Einwilligung.
      Die Bewerber werden dar&uuml;ber belehrt, dass ihre Zustimmung zur
      Aufnahme in den Talentpool freiwillig ist, keinen Einfluss auf das
      laufende Bewerbungsverfahren hat und sie ihre Einwilligung jederzeit
      f&uuml;r die Zukunft widerrufen k&ouml;nnen.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
        Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten
        (z.B. Eingaben in Onlineformularen); Bewerberdaten (z.B. Angaben zur
        Person, Post- und Kontaktadressen, die zur Bewerbung geh&ouml;renden
        Unterlagen und die darin enthaltenen Informationen, wie z.B.
        Anschreiben, Lebenslauf, Zeugnisse sowie weitere im Hinblick auf eine
        konkrete Stelle oder freiwillig von Bewerbern mitgeteilte Informationen
        zu deren Person oder Qualifikation).
      </li>
      <li><strong>Betroffene Personen:</strong> Bewerber.</li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Bewerbungsverfahren
        (Begr&uuml;ndung und etwaige sp&auml;tere Durchf&uuml;hrung sowie
        m&ouml;gliche sp&auml;tere Beendigung des
        Besch&auml;ftigungsverh&auml;ltnisses).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m408">Umfragen und Befragungen</h2>
    <p>
      Wir f&uuml;hren Umfragen und Befragungen durch, um Informationen f&uuml;r
      den jeweils kommunizierten Umfrage- bzw. Befragungszweck, zu sammeln. Die
      von uns durchgef&uuml;hrten Umfragen und Befragungen (nachfolgend
      "Befragungen") werden anonym ausgewertet. Eine Verarbeitung
      personenbezogener Daten erfolgt nur insoweit, als dies zu Bereitstellung
      und technischen Durchf&uuml;hrung der Umfragen erforderlich ist (z.B.
      Verarbeitung der IP-Adresse, um die Umfrage im Browser des Nutzers
      darzustellen oder mithilfe eines Cookies eine Wiederaufnahme der Umfrage
      zu erm&ouml;glichen).
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
        Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Kommunikationspartner; Teilnehmer.
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Feedback (z.B. Sammeln von
        Feedback via Online-Formular).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m263">Webanalyse, Monitoring und Optimierung</h2>
    <p>
      Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
      Auswertung der Besucherstr&ouml;me unseres Onlineangebotes und kann
      Verhalten, Interessen oder demographische Informationen zu den Besuchern,
      wie z.B. das Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit
      Hilfe der Reichweitenanalyse k&ouml;nnen wir z.B. erkennen, zu welcher
      Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am
      h&auml;ufigsten genutzt werden oder zur Wiederverwendung einladen. Ebenso
      k&ouml;nnen wir nachvollziehen, welche Bereiche der Optimierung
      bed&uuml;rfen.
    </p>
    <p>
      Neben der Webanalyse k&ouml;nnen wir auch Testverfahren einsetzen, um z.B.
      unterschiedliche Versionen unseres Onlineangebotes oder seiner
      Bestandteile zu testen und optimieren.
    </p>
    <p>
      Sofern nachfolgend nicht anders angegeben, k&ouml;nnen zu diesen Zwecken
      Profile, d.h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt und
      Informationen in einem Browser, bzw. in einem Endger&auml;t gespeichert
      und aus diesem ausgelesen werden. Zu den erhobenen Angaben geh&ouml;ren
      insbesondere besuchte Webseiten und dort genutzte Elemente sowie
      technische Angaben, wie der verwendete Browser, das verwendete
      Computersystem sowie Angaben zu Nutzungszeiten. Sofern Nutzer in die
      Erhebung ihrer Standortdaten uns gegen&uuml;ber oder gegen&uuml;ber den
      Anbietern der von uns eingesetzten Dienste einverstanden erkl&auml;rt
      haben, k&ouml;nnen auch Standortdaten verarbeitet werden.
    </p>
    <p>
      Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
      wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch K&uuml;rzung
      der IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
      Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie
      z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h.,
      wir als auch die Anbieter der eingesetzten Software kennen nicht die
      tats&auml;chliche Identit&auml;t der Nutzer, sondern nur den f&uuml;r
      Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten Angaben.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
        Webseiten, Interesse an Inhalten, Zugriffszeiten);
        Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
        Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile mit
        nutzerbezogenen Informationen (Erstellen von Nutzerprofilen); Tracking
        (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
        Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
      </li>
      <li>
        <strong>Sicherheitsma&szlig;nahmen:</strong> IP-Masking
        (Pseudonymisierung der IP-Adresse).
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
        a. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Google Analytics: </strong>Webanalyse, Reichweitenmessung sowie
        Messung von Nutzerstr&ouml;men; <strong>Dienstanbieter:</strong> Google
        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
        Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA; <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6
        Abs. 1 S. 1 lit. a. DSGVO); <strong>Website:</strong>
        <a
          href="https://marketingplatform.google.com/intl/de/about/analytics/"
          target="_blank"
          >https://marketingplatform.google.com/intl/de/about/analytics/</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a href="https://policies.google.com/privacy" target="_blank"
          >https://policies.google.com/privacy</a
        >; <strong>Auftragsverarbeitungsvertrag:</strong>
        <a
          href="https://business.safety.google/adsprocessorterms"
          target="_blank"
          >https://business.safety.google/adsprocessorterms</a
        >;
        <strong
          >Standardvertragsklauseln (Gew&auml;hrleistung Datenschutzniveau bei
          Verarbeitung in Drittl&auml;ndern):</strong
        >
        <a
          href="https://business.safety.google/adsprocessorterms"
          target="_blank"
          >https://business.safety.google/adsprocessorterms</a
        >;
        <strong>Widerspruchsm&ouml;glichkeit (Opt-Out):</strong> Opt-Out-Plugin:
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
          >https://tools.google.com/dlpage/gaoptout?hl=de</a
        >, Einstellungen f&uuml;r die Darstellung von Werbeeinblendungen:
        <a href="https://adssettings.google.com/authenticated" target="_blank"
          >https://adssettings.google.com/authenticated</a
        >; <strong>Weitere Informationen:</strong>
        <a
          href="https://privacy.google.com/businesses/adsservices"
          target="_blank"
          >https://privacy.google.com/businesses/adsservices</a
        >
        (Arten der Verarbeitung sowie der verarbeiteten Daten).
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m264">Onlinemarketing</h2>
    <p>
      Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings,
      worunter insbesondere die Vermarktung von Werbefl&auml;chen oder
      Darstellung von werbenden und sonstigen Inhalten (zusammenfassend als
      "Inhalte" bezeichnet) anhand potentieller Interessen der Nutzer sowie die
      Messung ihrer Effektivit&auml;t fallen kann.
    </p>
    <p>
      Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer
      Datei (sogenannte "Cookie") gespeichert oder &auml;hnliche Verfahren
      genutzt, mittels derer die f&uuml;r die Darstellung der vorgenannten
      Inhalte relevante Angaben zum Nutzer gespeichert werden. Zu diesen Angaben
      k&ouml;nnen z.B. betrachtete Inhalte, besuchte Webseiten, genutzte
      Onlinenetzwerke, aber auch Kommunikationspartner und technische Angaben,
      wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu
      Nutzungszeiten und genutzten Funktionen geh&ouml;ren. Sofern Nutzer in die
      Erhebung ihrer Standortdaten eingewilligt haben, k&ouml;nnen auch diese
      verarbeitet werden.
    </p>
    <p>
      Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
      wir zur Verf&uuml;gung stehende IP-Masking-Verfahren (d.h.,
      Pseudonymisierung durch K&uuml;rzung der IP-Adresse) zum Schutz der
      Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren keine
      Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert,
      sondern Pseudonyme. D.h., wir als auch die Anbieter der
      Onlinemarketingverfahren kennen nicht die tats&auml;chliche Identit&auml;t
      der Nutzer, sondern nur die in deren Profilen gespeicherten Angaben.
    </p>
    <p>
      Die Angaben in den Profilen werden im Regelfall in den Cookies oder
      mittels &auml;hnlicher Verfahren gespeichert. Diese Cookies k&ouml;nnen
      sp&auml;ter generell auch auf anderen Webseiten die dasselbe
      Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
      Darstellung von Inhalten analysiert als auch mit weiteren Daten
      erg&auml;nzt und auf dem Server des Onlinemarketingverfahrensanbieters
      gespeichert werden.
    </p>
    <p>
      Ausnahmsweise k&ouml;nnen Klardaten den Profilen zugeordnet werden. Das
      ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks
      sind, dessen Onlinemarketingverfahren wir einsetzen und das Netzwerk die
      Profile der Nutzer mit den vorgenannten Angaben verbindet. Wir bitten
      darum, zu beachten, dass Nutzer mit den Anbietern zus&auml;tzliche
      Abreden, z.B. durch Einwilligung im Rahmen der Registrierung, treffen
      k&ouml;nnen.
    </p>
    <p>
      Wir erhalten grunds&auml;tzlich nur Zugang zu zusammengefassten
      Informationen &uuml;ber den Erfolg unserer Werbeanzeigen. Jedoch
      k&ouml;nnen wir im Rahmen sogenannter Konversionsmessungen pr&uuml;fen,
      welche unserer Onlinemarketingverfahren zu einer sogenannten Konversion
      gef&uuml;hrt haben, d.h. z.B., zu einem Vertragsschluss mit uns. Die
      Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
      Marketingma&szlig;nahmen verwendet.
    </p>
    <p>
      Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
      verwendete Cookies f&uuml;r einen Zeitraum von zwei Jahren gespeichert
      werden.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
        Webseiten, Interesse an Inhalten, Zugriffszeiten);
        Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
        Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking (z.B.
        interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
        Marketing; Profile mit nutzerbezogenen Informationen (Erstellen von
        Nutzerprofilen).
      </li>
      <li>
        <strong>Sicherheitsma&szlig;nahmen:</strong> IP-Masking
        (Pseudonymisierung der IP-Adresse).
      </li>
      <li>
        <strong>Widerspruchsm&ouml;glichkeit (Opt-Out): </strong>Wir verweisen
        auf die Datenschutzhinweise der jeweiligen Anbieter und die zu den
        Anbietern angegebenen Widerspruchsm&ouml;glichkeiten (sog. "Opt-Out").
        Sofern keine explizite Opt-Out-M&ouml;glichkeit angegeben wurde, besteht
        zum einen die M&ouml;glichkeit, dass Sie Cookies in den Einstellungen
        Ihres Browsers abschalten. Hierdurch k&ouml;nnen jedoch Funktionen
        unseres Onlineangebotes eingeschr&auml;nkt werden. Wir empfehlen daher
        zus&auml;tzlich die folgenden Opt-Out-M&ouml;glichkeiten, die
        zusammenfassend auf jeweilige Gebiete gerichtet angeboten werden: a)
        Europa:
        <a href="https://www.youronlinechoices.eu" target="_blank"
          >https://www.youronlinechoices.eu</a
        >. b) Kanada:
        <a href="https://www.youradchoices.ca/choices" target="_blank"
          >https://www.youradchoices.ca/choices</a
        >. c) USA:
        <a href="https://www.aboutads.info/choices" target="_blank"
          >https://www.aboutads.info/choices</a
        >. d) Gebiets&uuml;bergreifend:
        <a href="https://optout.aboutads.info" target="_blank"
          >https://optout.aboutads.info</a
        >.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m299">Kundenrezensionen und Bewertungsverfahren</h2>
    <p>
      Wir nehmen an Rezensions- und Bewertungsverfahren teil, um unsere
      Leistungen zu evaluieren, zu optimieren und zu bewerben. Wenn Nutzer uns
      &uuml;ber die beteiligten Bewertungsplattformen oder -verfahren bewerten
      oder anderweitig Feedback geben, gelten zus&auml;tzlich die Allgemeinen
      Gesch&auml;fts- oder Nutzungsbedingungen und die Datenschutzhinweise der
      Anbieter. Im Regelfall setzt die Bewertung zudem eine Registrierung bei
      den jeweiligen Anbietern voraus.
    </p>
    <p>
      Um sicherzustellen, dass die bewertenden Personen tats&auml;chlich unsere
      Leistungen in Anspruch genommen haben, &uuml;bermitteln wir mit
      Einwilligung der Kunden die hierzu erforderlichen Daten im Hinblick auf
      den Kunden und die in Anspruch genommene Leistung an die jeweilige
      Bewertungsplattform (einschlie&szlig;lich Name, E-Mail-Adresse und
      Bestellnummer bzw. Artikelnummer). Diese Daten werden alleine zur
      Verifizierung der Authentizit&auml;t des Nutzers verwendet.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Vertragsdaten (z.B.
        Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B.
        besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
        Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Kunden; Nutzer (z.B.
        Webseitenbesucher, Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Feedback (z.B. Sammeln von
        Feedback via Online-Formular); Marketing.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <h2  class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m136">Pr&auml;senzen in sozialen Netzwerken (Social Media)</h2>
    <p>
      Wir unterhalten Onlinepr&auml;senzen innerhalb sozialer Netzwerke und
      verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
      Nutzern zu kommunizieren oder um Informationen &uuml;ber uns anzubieten.
    </p>
    <p>
      Wir weisen darauf hin, dass dabei Daten der Nutzer au&szlig;erhalb des
      Raumes der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen.
      Hierdurch k&ouml;nnen sich f&uuml;r die Nutzer Risiken ergeben, weil so
      z.B. die Durchsetzung der Rechte der Nutzer erschwert werden k&ouml;nnte.
    </p>
    <p>
      Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
      Regelfall f&uuml;r Marktforschungs- und Werbezwecke verarbeitet. So
      k&ouml;nnen z.B. anhand des Nutzungsverhaltens und sich daraus ergebender
      Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile
      k&ouml;nnen wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und
      au&szlig;erhalb der Netzwerke zu schalten, die mutma&szlig;lich den
      Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall
      Cookies auf den Rechnern der Nutzer gespeichert, in denen das
      Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner
      k&ouml;nnen in den Nutzungsprofilen auch Daten unabh&auml;ngig der von den
      Nutzern verwendeten Ger&auml;te gespeichert werden (insbesondere, wenn die
      Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
      eingeloggt sind).
    </p>
    <p>
      F&uuml;r eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
      und der Widerspruchsm&ouml;glichkeiten (Opt-Out) verweisen wir auf die
      Datenschutzerkl&auml;rungen und Angaben der Betreiber der jeweiligen
      Netzwerke.
    </p>
    <p>
      Auch im Fall von Auskunftsanfragen und der Geltendmachung von
      Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei
      den Anbietern geltend gemacht werden k&ouml;nnen. Nur die Anbieter haben
      jeweils Zugriff auf die Daten der Nutzer und k&ouml;nnen direkt
      entsprechende Ma&szlig;nahmen ergreifen und Ausk&uuml;nfte geben. Sollten
      Sie dennoch Hilfe ben&ouml;tigen, dann k&ouml;nnen Sie sich an uns wenden.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
        Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
        Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
        Kommunikation; Feedback (z.B. Sammeln von Feedback via Online-Formular);
        Marketing.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Instagram: </strong>Soziales Netzwerk;
        <strong>Dienstanbieter:</strong> Instagram Inc., 1601 Willow Road, Menlo
        Park, CA, 94025, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO);
        <strong>Website:</strong>
        <a href="https://www.instagram.com" target="_blank"
          >https://www.instagram.com</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a href="https://instagram.com/about/legal/privacy" target="_blank"
          >https://instagram.com/about/legal/privacy</a
        >.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
    <p>
      Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die
      von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als
      "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um
      Grafiken, Videos oder Stadtpl&auml;ne handeln (nachfolgend einheitlich
      bezeichnet als "Inhalte”).
    </p>
    <p>
      Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte
      die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die
      Inhalte nicht an deren Browser senden k&ouml;nnten. Die IP-Adresse ist
      damit f&uuml;r die Darstellung dieser Inhalte oder Funktionen
      erforderlich. Wir bem&uuml;hen uns, nur solche Inhalte zu verwenden, deren
      jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
      verwenden. Drittanbieter k&ouml;nnen ferner sogenannte Pixel-Tags
      (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) f&uuml;r
      statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags"
      k&ouml;nnen Informationen, wie der Besucherverkehr auf den Seiten dieser
      Webseite, ausgewertet werden. Die pseudonymen Informationen k&ouml;nnen
      ferner in Cookies auf dem Ger&auml;t der Nutzer gespeichert werden und
      unter anderem technische Informationen zum Browser und zum Betriebssystem,
      zu verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur
      Nutzung unseres Onlineangebotes enthalten als auch mit solchen
      Informationen aus anderen Quellen verbunden werden.
    </p>
    <ul class="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
        Webseiten, Interesse an Inhalten, Zugriffszeiten);
        Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen, IP-Adressen).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
        Onlineangebotes und Nutzerfreundlichkeit; Erbringung vertraglicher
        Leistungen und Kundenservice.
      </li>
      <li>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO).
      </li>
    </ul>
    <p>
      <strong
        >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
        Diensten:</strong
      >
    </p>
    <ul class="m-elements">
      <li>
        <strong>Google Maps: </strong>Wir binden die Landkarten des Dienstes
        “Google Maps” des Anbieters Google ein. Zu den verarbeiteten Daten
        k&ouml;nnen insbesondere IP-Adressen und Standortdaten der Nutzer
        geh&ouml;ren, die jedoch nicht ohne deren Einwilligung (im Regelfall im
        Rahmen der Einstellungen ihrer Mobilger&auml;te vollzogen), erhoben
        werden; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
        House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC,
        1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f. DSGVO); <strong>Website:</strong>
        <a href="https://cloud.google.com/maps-platform" target="_blank"
          >https://cloud.google.com/maps-platform</a
        >; <strong>Datenschutzerkl&auml;rung:</strong>
        <a href="https://policies.google.com/privacy" target="_blank"
          >https://policies.google.com/privacy</a
        >;
        <strong>Widerspruchsm&ouml;glichkeit (Opt-Out):</strong> Opt-Out-Plugin:
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
          >https://tools.google.com/dlpage/gaoptout?hl=de</a
        >, Einstellungen f&uuml;r die Darstellung von Werbeeinblendungen:
        <a href="https://adssettings.google.com/authenticated" target="_blank"
          >https://adssettings.google.com/authenticated</a
        >.
      </li>
    </ul>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m15">
      &Auml;nderung und Aktualisierung der Datenschutzerkl&auml;rung
    </h2>
    <p>
      Wir bitten Sie, sich regelm&auml;&szlig;ig &uuml;ber den Inhalt unserer
      Datenschutzerkl&auml;rung zu informieren. Wir passen die
      Datenschutzerkl&auml;rung an, sobald die &Auml;nderungen der von uns
      durchgef&uuml;hrten Datenverarbeitungen dies erforderlich machen. Wir
      informieren Sie, sobald durch die &Auml;nderungen eine Mitwirkungshandlung
      Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
      Benachrichtigung erforderlich wird.
    </p>
    <p>
      Sofern wir in dieser Datenschutzerkl&auml;rung Adressen und
      Kontaktinformationen von Unternehmen und Organisationen angeben, bitten
      wir zu beachten, dass die Adressen sich &uuml;ber die Zeit &auml;ndern
      k&ouml;nnen und bitten die Angaben vor Kontaktaufnahme zu pr&uuml;fen.
    </p>
    <h2 class="mb-4 text-2xl md:text-3xl font-bold tracking-tight text-gray-900 mt-5" id="m42">Begriffsdefinitionen</h2>
    <p>
      In diesem Abschnitt erhalten Sie eine &Uuml;bersicht &uuml;ber die in
      dieser Datenschutzerkl&auml;rung verwendeten Begrifflichkeiten. Viele der
      Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
      definiert. Die gesetzlichen Definitionen sind verbindlich. Die
      nachfolgenden Erl&auml;uterungen sollen dagegen vor allem dem
      Verst&auml;ndnis dienen. Die Begriffe sind alphabetisch sortiert.
    </p>
    <ul class="glossary">
      <li>
        <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind
        alle Informationen, die sich auf eine identifizierte oder
        identifizierbare nat&uuml;rliche Person (im Folgenden "betroffene
        Person“) beziehen; als identifizierbar wird eine nat&uuml;rliche Person
        angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu
        einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
        einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren
        besonderen Merkmalen identifiziert werden kann, die Ausdruck der
        physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
        kulturellen oder sozialen Identit&auml;t dieser nat&uuml;rlichen Person
        sind.
      </li>
      <li>
        <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
        Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw. kurz
        "Profilen" umfasst jede Art der automatisierten Verarbeitung
        personenbezogener Daten, die darin besteht, dass diese personenbezogenen
        Daten verwendet werden, um bestimmte pers&ouml;nliche Aspekte, die sich
        auf eine nat&uuml;rliche Person beziehen (je nach Art der Profilbildung
        k&ouml;nnen dazu unterschiedliche Informationen betreffend die
        Demographie, Verhalten und Interessen, wie z.B. die Interaktion mit
        Webseiten und deren Inhalten, etc.) zu analysieren, zu bewerten oder, um
        sie vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder
        Produkten, das Klickverhalten auf einer Webseite oder den
        Aufenthaltsort). Zu Zwecken des Profilings werden h&auml;ufig Cookies
        und Web-Beacons eingesetzt.
      </li>
      <li>
        <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als
        Web Analytics bezeichnet) dient der Auswertung der Besucherstr&ouml;me
        eines Onlineangebotes und kann das Verhalten oder Interessen der
        Besucher an bestimmten Informationen, wie z.B. Inhalten von Webseiten,
        umfassen. Mit Hilfe der Reichweitenanalyse k&ouml;nnen Webseiteninhaber
        z.B. erkennen, zu welcher Zeit Besucher ihre Webseite besuchen und
        f&uuml;r welche Inhalte sie sich interessieren. Dadurch k&ouml;nnen sie
        z.B. die Inhalte der Webseite besser an die Bed&uuml;rfnisse ihrer
        Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden h&auml;ufig
        pseudonyme Cookies und Web-Beacons eingesetzt, um wiederkehrende
        Besucher zu erkennen und so genauere Analysen zur Nutzung eines
        Onlineangebotes zu erhalten.
      </li>
      <li>
        <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das
        Verhalten von Nutzern &uuml;ber mehrere Onlineangebote hinweg
        nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die
        genutzten Onlineangebote Verhaltens- und Interessensinformationen in
        Cookies oder auf Servern der Anbieter der Trackingtechnologien
        gespeichert (sogenanntes Profiling). Diese Informationen k&ouml;nnen
        anschlie&szlig;end z.B. eingesetzt werden, um den Nutzern Werbeanzeigen
        anzuzeigen, die voraussichtlich deren Interessen entsprechen.
      </li>
      <li>
        <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
        nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder
        andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die
        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        entscheidet, bezeichnet.
      </li>
      <li>
        <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne
        Hilfe automatisierter Verfahren ausgef&uuml;hrte Vorgang oder jede
        solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der
        Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es
        das Erheben, das Auswerten, das Speichern, das &Uuml;bermitteln oder das
        L&ouml;schen.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PrivacyArea",
};
</script>

<style></style>
