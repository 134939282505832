<template>
  <div
    ref="container"
    id="appointmentArea"
    class="flex flex-col justify-center items-center min-h-screen w-full snap-start relative bg-blue-900 text-white max-h-[80rem] pt-24 scroll-py-60 pb-3"
  >
    <h1 class="mb-4 text-3xl md:text-5xl font-bold tracking-tight">
      {{appointmentHeadline}}
    </h1>
    <p class="text-center">
     {{appointmentText}}
    </p>
    
    <div class="flex flex-wrap justify-center mt-12 text-center">
         <a v-for="appointment in appointments" :key="appointment.id" :href="appointment.link" class="rounded text-gray-900 p-3 m-3 cursor-pointer bg-gray-100 hover:bg-white flex flex-col justify-center items-center w-72">
            <img :src="mediaProvider + appointment.Icon.url" :alt="appointment.Icon.alternativeText" class="w-16 h-16"/>
            <span class="font-bold mt-3">{{appointment.Title}}</span>
            <span class="font-semibold text-gray-600">{{appointment.Subtitle}}</span>
        </a>
    </div>

    <div class="border border-white rounded px-9 pt-9 mt-12 w-[19rem]">
      <table class="table-auto w-full mt-0">
        <thead>
          <tr class="text-left">
            <th>{{weekday}}</th>
            <th>{{openingHours}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="p-12" v-for="openingHoursObject in openingHoursArray" :key="openingHoursObject.id">
            <td>{{openingHoursObject.Day}}</td>
            <td>{{openingHoursObject.from.slice(0, -7) + ' - ' + openingHoursObject.to.slice(0, -7)}}</td>
          </tr>
        </tbody>
      </table>

      <address v-if="office" class="text-white mb-10 text-center font-bold mt-6 not-italic">
        {{office.imprint.officeStreet}} {{office.imprint.officeHouseNumber}}<br>
        {{office.imprint.officeZipCode}} {{office.imprint.officeCity}}<br>
      </address>
    </div>
    
  </div>
</template>

<script>
import * as env from "../../../environments";
import {ScrollService} from '../../../services/ScrollService';


export default {
  name: "AppointmentArea",
  props: {
    appointmentHeadline: String,
    appointmentText: String,
    weekday: String,
    openingHours: String,
    openingHoursArray: Array,
    appointments: Array,
    seo: Object,
    office: Object
  },
   mounted() {
    setTimeout(() => ScrollService.getInstance().addArea(
      'AppointmentArea', 
      this.$refs.container,  
      this.seo.browserTabTitle,
      this.seo.url,
      this.seo.metaDescription,
      this.seo.metaKeywords
      ), 100)
  },
  data: () => {
        return {
            mediaProvider: env.environments.mediaProvider
        }
    }
};
</script>

<style></style>
