<template>
  <li>
    <button
      v-if="title"
      v-bind:class="[isActive ? 'cursor-default font-bold text-gray-700' : 'hover:bg-gray-100 hover:text-gray-700 text-gray-500']"
      type="button"
      class="p-2 rounded"
      @click="$emit('click')"
    >
        {{title}}
    </button>
  </li>
</template>

<script>
export default {
    name: 'TopmenuItemUi',
    props: {
        isActive: Boolean,
        title: String
    }
};
</script>

<style></style>
