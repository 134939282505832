<template>
   <div class="flex flex-wrap justify-center m-3">
        <div class="row-span-3 rounded">
          <img
            :src="mediaProvider + employee.image.url"
            :srcset="mediaProvider + employee.image.formats.large.url + ' 830w,'+   
                  mediaProvider + employee.image.formats.medium.url + ' 622w,'+  
                  mediaProvider + employee.image.formats.small.url + ' 415w,'+  
                  mediaProvider + employee.image.formats.thumbnail.url + ' 129w'"
            loading="lazy"
            :alt="employee.image.alternativeText"
            class="w-72 h-96 object-cover rounded-3xl"
          />
        </div>
        <div class="flex flex-col py-5 md:pl-5 md:w-96">
          <h2 class="col-span-2 text-lg font-bold">{{employee.name}}</h2>
          <div class="col-span-2 text-blue-900 font-semibold">
            {{employee.position}}
          </div>
          <div class="col-span-2 mt-3">
            {{employee.text}}
          </div>
        </div>
      </div>
</template>

<script>

import * as env from "../../environments";

export default {
    name: 'EmployeeUi',
    props: {
      employee: Object
    },
    data: () => {
        return {
            mediaProvider: env.environments.mediaProvider
        }
    }
}
</script>

<style>

</style>