<template>
  <div
    ref="container"
    id="aboutArea"
    class="w-full snap-start relative flex flex-col justify-center items-center min-h-screen lg:p-24 lg:px-3 lg:pb-72 container mx-auto px-5 pt-16 pb-12"
  >
    <div class="w-full">
      <h1 class="mb-4 text-3xl md:text-5xl text-center font-bold tracking-tight text-gray-900">
        {{title}}
      </h1>
      <p>
        {{text}}
      </p>
    </div>
    <div class="bg-blue-100 px-4 py-3 rounded relative mx-3 my-5 w-full">
      <div class="flex sm:flex-row flex-col flex-nowrap justify-between">
        <div>
          <h2 class="mb-1 text-2xl md:text-3xl font-bold tracking-tight text-gray-900">
           {{applicationBannerTitle}}
          </h2>
          <span class="text-slate-500">{{applicationBannerSubTitle}}</span>
        </div>
        <button @click="toggleCollapse" type="button" class="sm:mt-0 mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2.5 sm:px-5 py-2.5 text-center mr-5">
          <span class="text-xs sm:text-base">
            {{ isOpen ? applicationBannerButtonActivated : applicationBannerButtonDecativated}}
          </span>
        </button>
      </div>

      <div class="mt-3 text-justify" v-if="isOpen">
        <p class="mb-3"><span class="font-bold">Wir suchen </span>{{applicationBannerButtonSocialRequirements}}</p>
        <p class="mb-3"><span class="font-bold">Folgendes bringst du mit: </span>{{applicationBannerButtonKnowledgeRequirements}}</p>
        <p class="mb-3"><span class="font-bold">Deine Vorteile: </span>{{applicationBannerButtonBenefits}}</p>
        <div class="font-bold">{{applicationBannerCallToAction}}<a  v-if="applicationBannerDoctolibLink && applicationBannerDoctolib" class="text-blue-800 font-bold underline" :href="applicationBannerDoctolibLink">{{applicationBannerDoctolib}}</a></div>
      </div>
    </div>
    <div class="flex flex-wrap justify-around w-full mt-9">
      <EmployeeUi v-for="employee in employees" :key="employee.id" :employee="employee"></EmployeeUi>
    </div>
  </div>
</template>

<script>
import EmployeeUi from "../../ui/EmployeeUi.vue";
import { ScrollService } from "../../../services/ScrollService";

export default {
  name: "AboutArea",
  components: {
    EmployeeUi,
  },
   props: {
    title: String,
    text: String,
     applicationBannerTitle: String,
     applicationBannerSubTitle: String,
     applicationBannerButtonActivated: String,
     applicationBannerButtonDecativated: String,
     applicationBannerButtonSocialRequirements: String,
     applicationBannerButtonKnowledgeRequirements: String,
     applicationBannerButtonBenefits: String,
     applicationBannerCallToAction: String,
     applicationBannerDoctolib: String,
     applicationBannerDoctolibLink: String,
    employees: Array,
    seo: Object
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen;
    }
  },
  mounted() {
    setTimeout(() => ScrollService.getInstance().addArea(
      "AboutArea", 
      this.$refs.container,  
      this.seo.browserTabTitle,
      this.seo.url,
      this.seo.metaDescription,
      this.seo.metaKeywords
    ), 100)
  },
};
</script>

<style></style>
