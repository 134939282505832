<template>
  <div
    ref="container"
    id="qualityPromiseArea"
    class="flex flex-col justify-center items-center lg:min-h-screen w-full snap-start relative scroll-pb-60 pt-16 pb-3 lg:pb-72 px-5 scroll-py-96"
  >
    <h1 class="mb-4 text-3xl md:text-5xl text-center font-bold tracking-tight text-gray-900">
      {{title}}
    </h1>
    <h2 class="text-gray-600 md:w-1/2 xl:w-1/3 text-center">
      {{subtitle}}
    </h2>
    <div class="flex mt-14 flex-wrap justify-center">
      <QualityPromiseUi 
        v-for="qualityPromise in qualityPromises" 
        :key="qualityPromise.id"
        :title="qualityPromise.title"
        :description="qualityPromise.description"
        :icon="qualityPromise.icon"
      ></QualityPromiseUi>
    </div>
  </div>
</template>

<script>
import QualityPromiseUi from "../../ui/QualityPromiseUi.vue";
import { ScrollService } from "../../../services/ScrollService";

export default {
  name: "QualityPromisesArea",
  components: {
    QualityPromiseUi,
  },
  props: {
    title: String,
    subtitle: String,
    qualityPromises: Array,
    seo: Object
  },
  mounted() {
    setTimeout(() => ScrollService.getInstance().addArea(
      "QualityPromisesArea",
      this.$refs.container,
       this.seo.browserTabTitle,
      this.seo.url,
      this.seo.metaDescription,
      this.seo.metaKeywords
    ), 100)
  },
};
</script>

<style></style>
