<template>
  <div ref="container" id="socialProofArea" class="min-h-[30rem] lg:min-h-screen w-full snap-start relative flex flex-col justify-end justify-self-center scroll-py-60">
    <div class="align-self-stretch my-auto pt-16">
      <h1 class="text-3xl md:text-5xl text-center font-bold mb-3">{{title}}</h1>
    </div>
    
    <div class="flex gap-12 justify-center bg-split-white-blue pb-12">
      <RatingUi 
        class="hidden md:block" 
        :text="ratings[0].Text" 
        :title="ratings[0].Title" 
        :name="ratings[0].Name"
      ></RatingUi>
      <RatingUi 
        class="hidden xl:block" 
       :text="ratings[1].Text" 
        :title="ratings[1].Title" 
        :name="ratings[1].Name"
      ></RatingUi>
      <RatingUi 
        :text="ratings[2].Text" 
        :title="ratings[2].Title" 
        :name="ratings[2].Name"
      ></RatingUi>
    </div>
  </div>
</template>

<script>
import { ScrollService } from "../../../services/ScrollService";
import RatingUi from "../../ui/RatingUi.vue";

export default {
  name: "SocialProofArea",
  components: {
    RatingUi,
  }, 
  props: {
    ratings: Array,
    title: String,
    seo: Object
  },
  mounted() {
    setTimeout(() => ScrollService.getInstance().addArea(
      "SocialProofArea",
      this.$refs.container,
      this.seo.browserTabTitle,
      this.seo.url,
      this.seo.metaDescription,
      this.seo.metaKeywords
    ), 100)
  },
};
</script>

<style></style>
