import { Area } from './../models/AreaModel';
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

export class ScrollService {
    
    private static _instance: ScrollService = new ScrollService();
    private _scrolling = Vue.observable({autoScroll: false, area: <Area | undefined>undefined});
    private areas: Area[] = [];

    get scrolling() {
        return this._scrolling;
    }

    set scrolling(scrolling) {
        this._scrolling = scrolling;
    }

    constructor(){
        if(ScrollService._instance){
            throw new Error("Error: Instantiation failed: Use ScrollService.getInstance() instead of new.");
        }
        ScrollService._instance = this;
    }


    public static getInstance():ScrollService
    {
        return ScrollService._instance;
    }

    public init() {
        Vue.use(VueScrollTo, {
            container: "main",
            duration: 500,
            easing: "ease",
            lazy: true,
            offset: 0,
            force: false,
            cancelable: true,
            onStart: () => {
               this.scrolling.autoScroll = true;
            },
            onDone: () => {
               this.scrolling.autoScroll = false;
            },
            onCancel: false,
            x: false,
            y: true
          });
    }

    public isMenuItemActive(name: string) {
        return this.scrolling.area?.name === name;
    }

    public addArea(name: string, area: HTMLElement, title: string, url: string, metaDescription: string, metaKeywords: string, isStart?: boolean) {
        const newArea = this.areas.find(area => area.name === name);
        if(!newArea) {
            const newArea = {
                htmlElement: area,
                name,
                title,
                url,
                metaDescription,
                metaKeywords,
                isStart
            } as Area
            this.areas.push(newArea);


            const hrefSplitted = location.href.split('/');
            if(isStart && hrefSplitted[3] === '') {
                this.changePage(newArea);
            } else if(hrefSplitted[3] !== '') {
                if(hrefSplitted[3] === newArea.url && !isStart) {
                    newArea.htmlElement.scrollIntoView();
                } else if(hrefSplitted[3] === newArea.url && isStart) {
                    this.changePage(newArea);
                }
            }
            
            return newArea;
        } else {
            newArea.htmlElement = area;
        }
       
    }

    public changePage = (e: undefined | Area) => {
       
        const newArea = e?.name ? e : this.areas.find(area => {
            return area.htmlElement.getBoundingClientRect().top - (window.innerHeight/2) <= window.innerHeight && area.htmlElement.getBoundingClientRect().bottom - (window.innerHeight/2) > 0;
        });
        if(newArea && ((newArea.name) !== this.scrolling.area?.name || newArea.isStart && this.scrolling.area === undefined)) {
            this.scrolling.area = newArea
            this.handleHistory(newArea)
            this.seoChanges(newArea);
        }
        
    }

    private seoChanges(area: Area) {
        document.title = 'Physiotherapie Recovery Guide - ' + area?.title;
        document.querySelector('meta[name="description"]')?.setAttribute("content", area.metaDescription);
        document.querySelector('meta[name="keywords"]')?.setAttribute("content", area.metaKeywords);
    }

    private handleHistory(area?: Area) {
        const nextURL = area?.url;
        const nextTitle = area?.title ?? '';
        const nextState = {};

        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, nextURL);
    }

    public scrollTo(route: string) {
        const area = this.areas.find(area => area.url === route.substring(1));
        if(area) {
            area.htmlElement.scrollIntoView({behavior: 'smooth'});
        }
    }
}