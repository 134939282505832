<template>
  <div class="max-w-sm bg-white rounded-lg shadow-md">
    <div class="p-6">
      <a href="#">
        <h2 class="text-xl font-semibold tracking-tight text-gray-900">
          {{title}}
        </h2>
      </a>
      <div
        class="inline-flex bg-blue-100 text-blue-800 text-xs font-semibold mr-2 my-2 px-2.5 py-0.5 rounded"
      >
        {{name}}
      </div>
      <div>
       {{text}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingUi",
  props: {
    title: String,
    name: String,
    text: String
  }
};
</script>

<style></style>
