<template>
  <button
    @click="toggleState()"
    :aria-label="isActive ? 'Menü schließen' : 'Menü öffnen'"
    class="lg:hidden flex items-center space-x-2 focus:outline-none"
  >
    <span class="sr-only" v-if="openMenuText && closeMenuText">{{ isActive ? openMenuText : closeMenuText }}</span>
    <div class="w-6 flex items-center justify-center relative">
      <span
        v-bind:class="isActive ? 'translate-y-0 rotate-45' : '-translate-y-2'"
        class="transform transition w-full h-px bg-gray-700 absolute"
      ></span>

      <span
        v-bind:class="isActive ? 'opacity-0 translate-x-3' : 'opacity-100'"
        class="transform transition w-full h-px bg-gray-700 absolute"
      ></span>

      <span
        v-bind:class="isActive ? 'translate-y-0 -rotate-45' : 'translate-y-2'"
        class="transform transition w-full h-px bg-gray-700 absolute"
      ></span>
    </div>
  </button>
</template>

<script>
export default {
  name: "HamburgerUi",
  props: {
    openMenuText: null,
    closeMenuText: null
  },
  data: function () {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleState: function () {
      this.$emit("activeChange", !this.isActive);
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped></style>
